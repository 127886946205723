module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"BlindMonkey","short_name":"BlindMonkey","start_url":"/","background_color":"#FFFFFF","theme_color":"#e3000f","display":"minimal-ui","icon":"src/assets/icons/affe.png"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
